import React, { useEffect, useState } from "react";
import BaseApi from "../../Api/BaseApi";

function PaymentDetails() {
  const [paymentsData, setPaymentsData] = useState([]);

  async function fetchDetails() {
    try {
      const res = await BaseApi.get(
        process.env.REACT_APP_API_ENDPOINT + "/get-all-payments"
      );
      setPaymentsData(res.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchDetails();
  }, []);
  function convertTimestampToDate(timestamp, timezoneOffset = 0) {
    const date = new Date(timestamp * 1000 + timezoneOffset * 3600000);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    
    return `${year}-${month}-${day}`;
  }
  return (
    <>
      <div className="inbox-body border-t ">
        <div className="inbox-body-inner">
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
              <div className="py-2 min-w-full">
                <div className="overflow-hidden">
                  <div className="flex justify-between items-center my-5">
                    <h2 className="text-3xl font-bold">Payment List</h2>
                  </div>
                  <div className="sm:overflow-x-hidden overflow-x-scroll">
                    <table className="min-w-full">
                      <thead className="bg-white border-b">
                        <tr>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Subscription ID
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            User ID
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4"
                          >
                            Amount
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4"
                          >
                            Currency
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4"
                          >
                            Interval
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4"
                          >
                            Plan Start
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4"
                          >
                            Plan End
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentsData?.map((item, i) => (
                          <tr className="bg-gray-100 border-b" key={item?._id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {item.subscriptionId}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {item.status}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {item.userId}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {item.email}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            ${item.amount}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.currency && item.currency}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.interval && item.interval}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {convertTimestampToDate(item.created)}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {item?.planEnd && convertTimestampToDate(item?.planEnd)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentDetails;
