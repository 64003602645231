import React from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import BaseApi from "../../Api/BaseApi";
import { useAppContext } from "../../context/AppContext";
import { cancelSubscription, resumeSubscription } from "../../Api/ExportApi";
import ToastHandle from "../../Components/helpers/ToastMessage";
const stripePromise = loadStripe(
  process.env.REACT_APP_API_STRIPE_PUBLISHABLE_KEY
);

const Plans = () => {
  const { plan, subscription, subscriptionId, subscriptionStatus } =
    useAppContext();

  const handleCancelSubscription = async () => {
    try {
      const resp = await cancelSubscription(subscriptionId);

      if (resp.status === 200) {
        ToastHandle("Subscription cancelled Successfully", "success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleResumeSubscription = async () => {
    try {
      const resp = await resumeSubscription(subscriptionId);

      if (resp.status === 200) {
        ToastHandle("Subscription resumed Successfully", "success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function makePayment(subscriptionMode, subsData) {
    // Call your backend to create a checkout session

    try {
      // const { data } = await BaseApi.post(
      //   process.env.REACT_APP_API_ENDPOINT + "/create-product",
      //   {
      //     name: subsData.name, // Example: Replace with dynamic product name
      //     description: subsData.disc, // Example: Replace with dynamic description
      //     price: subsData.price * 100, // Price in cents (2000 cents = $20)
      //     subscriptionMode: subscriptionMode,
      //   }
      // );

      // // Get the priceId from the response
      // const { priceId } = data;

      // Now create a Checkout Session with the priceId
      const session = await BaseApi.post(
        process.env.REACT_APP_API_ENDPOINT + "/create-checkout-session",
        {
          subscriptionMode: subscriptionMode,
        }
      );

      // const response = await fetch(
      //  process.env.REACT_APP_API_ENDPOINT+ "/create-checkout-session",
      //   {
      //     method: "POST",
      //   }
      // );

      // const session = await response.json();

      // Redirect to Stripe Checkout

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.data.sessionId,
      });

      if (error) {
        console.log("Error redirecting to checkout:", error);
      }
    } catch (error) {
      console.log(error);
    }
  }
  // async function makePayment(subscriptionMode) {
  //   const stripe = await loadStripe(process.env.REACT_APP_API_STRIPE_PUBLISHABLE_KEY);
  //   try {
  //     const { data } = await axios.post('http://localhost:3005/api/create-payment', {
  //       subscriptionMode,
  //       quantity:1
  //     });
  //     const sessionId = data.sessionId;

  //     const result = await stripe.redirectToCheckout({ sessionId });

  //     if (result.error) {
  //       console.error("Payment failed: " + result.error.message);
  //     }
  //   } catch (error) {
  //     console.error("Payment process error:", error);
  //     // toast.error("Payment initialization failed");
  //   }

  // }
  return (
    <>
      <div className="flex min-h-screen pt-[30px] px-[40px]">
        <div className="min-w-full">
          <p className="text-[#00153B] text-[20px] leading-[40px] font-semibold">
            Your Subscription
          </p>
          <div>
            <p className="text-[#717F87] text-[15px] leading-[27px] font-medium">
              Aliquam sagittis sapien in nibh tincidunt fermentum. Morbi
              eleifend faucibus.
            </p>
          </div>
          {/* <div className="mt-[30px] inline-flex border border-[#E1E3E5] shadow-[0px 1px 2px #E1E3E5] divide-[#E1E3E5] divide-x rounded-[5px]">
            <button className="bg-white hover:bg-[#F6F6F7] hover:text-[#717F87] text-[#0E1823] leading-[16px] text-[13px] font-bold py-[15px] px-[25px] rounded-l">
              Monthly
            </button>
            <button className="bg-white hover:bg-[#F6F6F7] hover:text-[#717F87] text-[#0E1823] text-[13px] leading-[16px] font-bold py-[15px] px-[25px] rounded-r">
              Annual
            </button>
          </div> */}
          <div className="mt-[20px] grid grid-cols sm:grid-cols-2 gap-[20px]">
            <div
              key={1}
              className="w-full bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y"
            >
              <div className="pt-[15px] px-[25px] pb-[25px]">
                <div className="flex justify-end">
                  <div className="bg-[#F6F6F7] rounded-[20px] flex justify-center align-center px-[12px]">
                    <p className="text-[#00153B] text-[12px] leading-[28px] font-bold">
                      Basic
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-[#00153B] text-[19px] leading-[24px] font-bold">
                    Starter
                  </p>
                  <div className="flex items-end">
                    <p className="text-[#00153B] text-[50px] leading-[63px] font-bold">
                      $3
                    </p>
                    <p className="text-[#717F87] text-[18px] leading-[28px] font-medium">
                      /month
                    </p>
                  </div>
                </div>
                {/* <div>
                  <p className="text-[#717F87] text-[18px] leading-[28px] font-medium">
                    1 User
                  </p>
                </div> */}
              </div>
              <div className="pt-[25px] px-[25px] pb-[35px]">
                <div>
                  <p className="text-[#717F87] text-[14px] leading-[24px] font-medium">
                    {process.env.REACT_APP_MONTHLY_DOMIANS_ALLOWED} domains
                  </p>
                  <p className="text-[#717F87] text-[14px] leading-[24px] font-medium">
                    {process.env.REACT_APP_MONTHLY_CUSTOM_EMAILS_ALLOWED} custom emails
                  </p>
                </div>
                <div className="mt-[25px]">
                  {subscription &&
                  plan === "month" &&
                  subscriptionStatus === "paid" ? (
                    <button
                      onClick={handleCancelSubscription}
                      className="bg-[#acacac] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                    >
                      Cancel
                    </button>
                  ) : (
                    <>
                      {plan !== "month" && (
                        <button
                          onClick={() =>
                            makePayment("month", {
                              name: "Basic",
                              price: 3,
                              disc: "Starter",
                            })
                          }
                          className="bg-[#006EF5] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                        >
                          Subscribe
                        </button>
                      )}

                      {plan === "month" &&
                        subscriptionStatus === "canceling" && (
                          <button
                            onClick={handleResumeSubscription}
                            className="bg-[#006EF5] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                          >
                            Resume
                          </button>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* <div
              key={2}
              className="w-full bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y"
            >
              <div className="pt-[15px] px-[25px] pb-[25px]">
                <div className="flex justify-end">
                  <div className="bg-[#F6F6F7] rounded-[20px] flex justify-center align-center px-[12px]">
                    <p className="text-[#00153B] text-[12px] leading-[28px] font-bold">
                      Value
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-[#00153B] text-[19px] leading-[24px] font-bold">
                    Fast Start
                  </p>
                  <p className="text-[#00153B] text-[50px] leading-[63px] font-bold">
                    $49
                  </p>
                </div>
                <div>
                  <p className="text-[#717F87] text-[18px] leading-[28px] font-medium">
                    50 Credits per month
                  </p>
                  <p className="text-[#717F87] text-[18px] leading-[28px] font-medium">
                    Unlimited users
                  </p>
                </div>
              </div>
              <div className="pt-[25px] px-[25px] pb-[35px]">
                <div>
                  <p className="text-[#717F87] text-[14px] leading-[24px] font-medium">
                    Direct Phone Numbers
                  </p>
                  <p className="text-[#717F87] text-[14px] leading-[24px] font-medium">
                    Landline Phone Numbers
                  </p>
                  <p className="text-[#717F87] text-[14px] leading-[24px] font-medium">
                    Corporate email addresses
                  </p>
                  <p className="text-[#717F87] text-[14px] leading-[24px] font-medium">
                    Propsetcs
                  </p>
                  <p className="text-[#717F87] text-[14px] leading-[24px] font-medium">
                    Chrome Extension
                  </p>
                </div>
                <div className="mt-[25px]">
                  <button className="bg-[#E1E3E5] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold">
                    Current Plan
                  </button>
                </div>
              </div>
            </div> */}
            <div
              key={3}
              className="w-full bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y"
            >
              <div className="pt-[15px] px-[25px] pb-[25px]">
                <div className="flex justify-end">
                  <div className="bg-[#F6F6F7] rounded-[20px] flex justify-center align-center px-[12px]">
                    <p className="text-[#00153B] text-[12px] leading-[28px] font-bold">
                      Pro
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-[#00153B] text-[19px] leading-[24px] font-bold">
                    Accelerate
                  </p>
                  <div className="flex items-end">
                    <p className="text-[#00153B] text-[50px] leading-[63px] font-bold">
                      $29
                    </p>
                    <p className="text-[#717F87] text-[18px] leading-[28px] font-medium">
                      /year
                    </p>
                  </div>
                </div>
                {/* <div>
                  <p className="text-[#717F87] text-[18px] leading-[28px] font-medium">
                    Unlimited users
                  </p>
                </div> */}
              </div>
              <div className="pt-[25px] px-[25px] pb-[35px]">
                <div>
                  <p className="text-[#717F87] text-[14px] leading-[24px] font-medium">
                    {process.env.REACT_APP_YEARLY_DOMAINS_ALLOWED} domains
                  </p>
                  <p className="text-[#717F87] text-[14px] leading-[24px] font-medium">
                    {process.env.REACT_APP_YEARLY_CUSTOM_EMAILS_ALLOWED} custom emails
                  </p>
                </div>
                <div className="mt-[25px]">
                  {subscription &&
                  plan === "year" &&
                  subscriptionStatus === "paid" ? (
                    <button
                      onClick={handleCancelSubscription}
                      className="bg-[#acacac] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                    >
                      Cancel
                    </button>
                  ) : (
                    <>
                      {plan !== "year" && (
                        <button
                          onClick={() =>
                            makePayment("year", {
                              name: "Pro",
                              price: 29,
                              disc: "Accelerate",
                            })
                          }
                          className="bg-[#006EF5] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                        >
                          Subscribe
                        </button>
                      )}

                      {plan === "year" &&
                        subscriptionStatus === "canceling" && (
                          <button
                            onClick={handleResumeSubscription}
                            className="bg-[#006EF5] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                          >
                            Resume
                          </button>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plans;
