import { jwtDecode } from "jwt-decode";
import React, { createContext, useState, useContext, useEffect } from "react";
import { userDatabyid } from "../Api/ExportApi";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [plan, setPlan] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;

          const resp = await userDatabyid(userId);
          console.log(resp);

          login(
            resp?.data?.user,
            token,
            resp?.data?.user?.role,
            resp?.data?.user?.plan,
            resp?.data?.user?.subscription,
            resp?.data?.user?.subscriptionId,
            resp?.data?.user?.subscriptionStatus
          );
          setLoading(false);
        } catch (err) {
          console.error("Error fetching user data:", err.message);
        }
      } else {
        console.log("No token found");
        localStorage.clear();
        if(window.location.pathname !== `/login` && window.location.pathname !== `/register`){
          window.location.href = "/login";
        }
      }
    };

    fetchUserData();
  }, [authToken]);

  const login = (
    userData,
    token,
    role,
    plan,
    subscription,
    subscriptionId,
    subscriptionStatus
  ) => {
    setUser(userData);
    setAuthToken(token);
    setUserRole(role);
    setPlan(plan);
    setSubscription(subscription);
    setSubscriptionId(subscriptionId);
    setSubscriptionStatus(subscriptionStatus);
  };

  const logout = () => {
    setUser(null);
    setAuthToken(null);
    setUserRole(null);
  };

  return (
    <AppContext.Provider
      value={{
        user,
        authToken,
        plan,
        subscription,
        subscriptionId,
        subscriptionStatus,
        userRole,
        login,
        logout,
        loading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
